<template>
  <div class="loginView">
    <login-unit />
    <modal-state />
  </div>
</template>

<script>
// Components
import LoginUnit from "@/components/organisms/login/LoginUnit.vue";
import ModalState from "@/components/organisms/common/ModalState.vue";
// Store
import { mapActions, mapGetters, mapState } from "vuex";

import router from "@/pages/login/router";

export default {
  components: {
    LoginUnit,
    ModalState,
  },
  methods: {
    ...mapActions({ init: "initialize" }),
    ...mapActions(["showErrorModal"]),
  },
  async created() {
    await this.init();
    const route = router.currentRoute.value;
    if ("unauthorized" in route.query) {
      this.showErrorModal("unauthorized");
      this.$router.push(location.pathname); // query 削除
    }
  },
};
</script>

<style scoped lang="scss"></style>
