import { createApp } from "vue";

import App from "@/pages/login/App.vue";
import router from "@/pages/login/router.js";
import store from "@/store/login";

import { setGuard } from "@/lib/router/index.js";
setGuard(router);

createApp(App).use(router).use(store).mount("#app");
