const mutations = {
  UPDATE_ERROR_TEXT(state, payload) {
    state.errorText = payload;
  },
  UPDATE_PATH: function(state, payload) {
    state.path = payload;
  },
};

export default mutations;
