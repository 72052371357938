import { createStore } from "vuex";

// Store Module
import commonModule from "@/store/common";
import modalModule from "@/store/modal";
import setupModule from "@/store/login/SetupModule";

import actions from "@/store/login/actions";
import getters from "@/store/login/getters";
import mutations from "@/store/login/mutations";

export default createStore({
  modules: {
    common: commonModule,
    modal: modalModule,
    setup: setupModule,
  },
  state: {
    errorText: "",
    path: "",
  },
  actions,
  getters,
  mutations,
});
