import {
  getCookie,
  removeCookie,
  getCookieAccount,
  removeCookieAccount,
} from "@/lib/cookie";
import API_CODE from "@/assets/common/ApiCode";
import * as commonTypes from "@/store/common/mutationTypes";

// 式場アカウント一覧に移動できる管理者role
const HALL_ADMIN_ACCOUNT_ROLES = [
  "super_admin",
  "maintenance_admin",
  "maintenance_user",
  "data_creation_user",
];

const actions = {
  /**
   * 初期化
   */
  async initialize({ commit, dispatch, state }) {
    const account = getCookieAccount();
    let path = getCookie("path");
    if (path && path.search(/(\d+)/) !== -1) path = "/"; // 各機能の詳細ページの場合は top に移動
    let isLogout = true;
    if (account.token) {
      isLogout = await dispatch("common/logout"); // ログアウト
    }
    removeCookieAccount(); // cookie を削除
    if (!isLogout) {
      dispatch("showErrorModal", "logout");
    }
    if (path) {
      commit("UPDATE_PATH", path); // ログイン後のパス指定を store に保存
      removeCookie("path");
    }
  },
  /**
   * ログイン
   * @param {Object} payload { userable_id: 式場ID, name: ユーザー名 password: パスワード }
   */
  async login({ commit, dispatch, state, getters }, payload) {
    // const { userable_id, name, password } = payload;
    commit("modal/setUpdateModal", { isShow: true, text: "ログイン中です…" });
    const status = await dispatch("common/login", payload);
    if (status === API_CODE.response.success) {
      // ログイン成功
      await dispatch("common/getLoginAccount");
      const { role } = getters["common/account"];
      const isAdmin = HALL_ADMIN_ACCOUNT_ROLES.includes(role);
      const defaultPath = isAdmin ? "/hall-admin" : "/";
      window.location.href = state.path || defaultPath;
    } else if (status === API_CODE.response.conflict) {
      // ログイン済み (409:token発行済)
      commit("modal/setUpdateModal", { isShow: false });
      let loginParam = payload;
      loginParam.force_login = true;
      // 強制ログイン確認
      const modalParam = {
        text: state.setup.errorTexts.conflict,
        status: 3,
        actionName: "login",
        actionBtnText: "ログイン",
        closeBtnText: "キャンセル",
        param: loginParam,
      };
      dispatch("modal/showConfirmModal", modalParam);
    } else {
      // その他エラー
      commit("modal/setUpdateModal", { isShow: false });
      dispatch("showErrorModal", "login");
      return false; // 失敗した場合は即座にfalseを返却して処理を終了する
    }
    return true; // 成功した場合はtrueを返却する
  },
  /**
   * エラーモーダルのメッセージを変更する
   * @param {String} text メッセージ
   */
  setErrorText({ commit }, text) {
    commit("UPDATE_ERROR_TEXT", text);
  },
  /**
   * エラーモーダルを表示する
   * @param {String} type セット済みのメッセージが無い場合に type 別デフォルトメッセージを表示
   */
  showErrorModal({ commit, state }, type) {
    commit("common/" + commonTypes.ERROR);
    const errorModal = {
      isShow: true,
      text: state.errorText || state.setup.errorTexts[type],
    };
    commit("modal/setErrorModal", errorModal);
  },
};
export default actions;
