<template>
  <div class="loginUnit">
    <div class="loginUnit_box">
      <p class="title_ksm fontNum">{{ config.ksm }}</p>
      <h1 class="title_pj">
        <img src="/img/coremone_logo_login.png" :alt="config.name" width="365" />
      </h1>
      <dl class="inputItem">
        <dt class="inputItem_title">ID</dt>
        <dd class="inputItem_form">
          <input
            class="inputBox"
            ref="id"
            type="text"
            v-model="userableId"
            @keydown.enter="focus('name')"
          />
        </dd>
      </dl>
      <dl class="inputItem">
        <dt class="inputItem_title">ユーザー名</dt>
        <dd class="inputItem_form">
          <input
            class="inputBox"
            ref="name"
            type="text"
            v-model="name"
            @keydown.enter="focus('password')"
          />
        </dd>
      </dl>
      <dl class="inputItem">
        <dt class="inputItem_title">パスワード</dt>
        <dd class="inputItem_form">
          <input
            class="inputBox"
            ref="password"
            type="password"
            v-model="password"
            @keydown.enter="focus('id')"
          />
        </dd>
      </dl>
      <action-button
        class="loginBtn"
        ref="loginBtn"
        text="ログイン"
        :is-disabled="!isCompleteForm"
        :is-large="true"
        :is-save="true"
        @clickHandler="clickLogin"
      />
    </div>
    <div class="guide">
      <p class="guide_text">{{ config.name }}のご利用にはお申し込みが必要です。</p>
      <p class="guide_text">お申し込み・お問い合わせは下記までお願いします。</p>
      <ul class="guide_contact">
        <li class="guide_contactTel">
          <dl class="guide_tel">
            <dt class="guide_tel_title">ご利用希望の式場様</dt>
            <dd>
              <i class="material-icons">phone</i>
              <span class="guide_tel_detail fontNum">{{ config.applicationTel }}</span>
            </dd>
          </dl>
          <dl class="guide_tel">
            <dt class="guide_tel_title">ご利用中の式場様</dt>
            <dd>
              <i class="material-icons">phone</i>
              <span class="guide_tel_detail fontNum">{{ config.tel }}</span>
            </dd>
          </dl>
        </li>
        <li class="guide_contactInfo">
          <span class="guide_note">受付時間：{{ config.time }}</span>
        </li>
        <li class="guide_contactInfo fontNum">
          <i class="material-icons">mail_outline</i>
          <a class="guide_link" :href="`mailto:${config.mail}`">{{ config.mail }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// Components
import ActionButton from "@/components/atoms/common/ActionButton";
// Store
import { mapActions, mapGetters, mapState } from "vuex";
// assets
import { IS_DEVELOP_SERVER } from "@/assets/common/ServerConfig";
import MASTER_CONFIG from "@/assets/common/MasterConfig";

export default {
  components: {
    ActionButton,
  },
  data() {
    return {
      userableId: "",
      name: "",
      password: "",
      isSetId: false,
    };
  },
  computed: {
    ...mapState("modal", {
      completeModal: (state) => state.completeModal,
      confirmModal: (state) => state.confirmModal,
      errorModal: (state) => state.errorModal,
      loadingModal: (state) => state.loadingModal,
    }),
    isShowModal: function() {
      return (
        this.completeModal.isShow ||
        this.confirmModal.isShow ||
        this.errorModal.isShow ||
        this.loadingModal.isShow
      );
    },
    isCompleteForm: function() {
      return this.userableId && this.password && !this.isShowModal;
    },
  },
  created() {
    this.config = MASTER_CONFIG;
  },
  mounted() {
    const path = location.pathname.split("/");
    if (path.length >= 3 && path[2]) {
      const id = Number(path[2]);
      if (id) {
        this.userableId = id;
        this.isSetId = true;
      }
    }
    this.focus(this.isSetId ? "name" : "id");
  },
  methods: {
    ...mapActions(["login"]),
    clickLogin: function() {
      if (!this.isShowModal && this.isCompleteForm) {
        this.login({
          userable_id: this.userableId,
          name: this.name,
          password: this.password,
          force_login: false,
        }).then((isSuccess) => {
          if (!isSuccess) {
            this.password = "";
          }
        });
      }
    },
    focus: function(target) {
      if (!this.isShowModal) {
        if (this.isCompleteForm) {
          this.clickLogin();
        } else {
          this.$refs[target].focus();
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.loginUnit {
  margin: $GRID * 1.5 auto auto;
  width: $GRID * 39;
  &_box {
    @include gradientLogin();
    border-radius: $BOX_RADIUS;
    box-shadow: $DEPTH_LV_1;
    height: $GRID * 29;
    margin-bottom: $GRID * 1.5;
    padding-top: $GRID * 2;
    .inputItem {
      align-items: center;
      display: flex;
      margin: 0 auto $GRID;
      width: $GRID * 24;
      &_title {
        font-size: 14px;
        width: $GRID * 6;
      }
      &_form {
        width: calc(100% - #{$GRID} * 6);
      }
    }
    .loginBtn {
      margin: $GRID * 2 auto 0;
    }
    .title {
      &_pj {
        color: $COLOR_KEY;
        text-align: center;
        font-size: 2.8rem;
        margin: 0 0 $GRID * 2;
      }
      &_ksm {
        color: $COLOR_KEY;
        text-align: center;
        font-size: 16px;
        margin-bottom: $SUB_GRID * 1;
        margin-top: $SUB_GRID;
      }
    }
  }
  .guide {
    border: 1px solid $COLOR_SUB_LIGHT_TEXT;
    border-radius: $BOX_RADIUS;
    box-sizing: border-box;
    color: $COLOR_SUB_TEXT;
    padding: $GRID;
    text-align: center;
    &_link {
      color: $COLOR_SUB_TEXT;
      &:hover {
        text-decoration: none;
      }
    }
    &_text {
      margin-top: $SUB_GRID;
      &:first-child {
        margin-top: 0;
      }
    }
    &_tel {
      background: rgba(255, 255, 255, 0.9);
      border-radius: $BOX_RADIUS;
      font-size: 22px;
      padding: $GRID * 0.5;
      text-align: center;
      width: $GRID * 16.5;
      &_title {
        font-size: 12px;
        text-align: center;
      }
    }
    &_note {
      font-size: 12px;
    }
    &_contact {
      list-style: none;
      .material-icons {
        padding-right: 4px;
        vertical-align: -0.3em;
      }
      &Info {
        margin-top: $SUB_GRID;
      }
      &Tel {
        display: flex;
        justify-content: center;
        margin: $SUB_GRID * 3 auto;
        > * + * {
          margin-left: $GRID;
        }
        .material-icons {
          font-size: 20px;
          padding-right: 0;
          vertical-align: -0.2em;
        }
      }
    }
  }
  .inputBox {
    background: $BG_COLOR_BOX;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: block;
    font-size: 20px;
    outline: none;
    padding: 7px 10px 6px;
    width: 100%;
  }
}
</style>
