/**
 * セットアップ時の状態を管理するモジュール
 */
import MESSAGE_CONFIG from "@/assets/common/MessageConfig.js";

const Setup = {
  namespaced: true,
  state: {
    errorTexts: {
      conflict: MESSAGE_CONFIG.response.conflict,
      login: "ログインできません。入力内容を確認して再度実行してください。",
      logout: "正常にログアウトできませんでした。",
      unauthorized: MESSAGE_CONFIG.response.unauthorized,
    },
  },
};

export default Setup;
