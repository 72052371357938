<template>
  <div class="isLoginPage" id="page">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss">
@import "@/assets/scss/common.scss";
</style>
