import { createRouter, createWebHistory } from "vue-router";
import LoginView from "@/views/LoginView.vue";
import ErrorView from "@/views/ErrorView.vue";

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/login/:id",
      component: LoginView,
    },
    {
      path: "/login",
      component: LoginView,
    },
    {
      name: "error",
      path: "/:pathMatch(.*)*",
      component: ErrorView,
    },
  ],
});
